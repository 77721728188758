import React, { useState } from 'react';
import { Link, graphql, withPrefix } from 'gatsby';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { Location } from '../models';
import EmailPopup from '../components/email-popup';

interface Props {
  location: Location;
  data: {
    allConfig: {
      nodes: { name: string; value: string }[];
    };
  };
}

const MINICRM_URL = process.env.MINICRM_URL || '';
const MINICRM_SYSTEM_ID = process.env.MINICRM_SYSTEM_ID || '';
const MINICRM_API_KEY = process.env.MINICRM_API_KEY || '';

const Workshop = ({ data, location }: Props) => {
  // console.log("location: ", location)
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [telephone, setTelephone] = useState<string>('');
  const [organization, setOrganization] = useState<string>('');
  const [remark, setRemark] = useState<string>('');
  const [formState, setFormState] = useState<'initial' | 'pending' | 'sent'>('initial');
  
  const[date, setDate] = useState(new Date());

  const handleSubmit = (e: React.SyntheticEvent) => {
    setFormState('pending');
    fetch(MINICRM_URL, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        'Authorization': 'Basic ' + Buffer.from(MINICRM_SYSTEM_ID + ":" + MINICRM_API_KEY, 'base64')
      },
      body: JSON.stringify({ name, email, organization, telephone, remark }),
    })
      .then(() => setFormState('sent'))
      .catch(() => setFormState('sent'));
    e.preventDefault();
  };

  const clickHandler = () => {
    console.log("calendar value: ", date)
  }

  function onChange(nextValue:Date) {
    console.log("Next selected Date: ", nextValue)
    setDate(nextValue);
  }
  
  return (
    <div id="main_content">
      <Navbar dark={true} />
      <EmailPopup/>

      <section className="page-banner">
        <div className="container">
          <div className="page-title-wrapper">
            <h1 className="page-title">Workshop</h1>

            <ul className="bradcurmed">
              <li>
              <Link partiallyActive={true} to="/">
                    Home
              </Link>
              </li>
              <li>Workshop</li>
            </ul>
          </div>
        </div>

        <svg
          className="circle"
          data-parallax='{"x" : -200}'
          xmlns="http://www.w3.org/2000/svg"
          width="950px"
          height="950px"
        >
          <path
            fillRule="evenodd"
            stroke="rgb(250, 112, 112)"
            strokeWidth="100px"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            opacity="0.051"
            fill="none"
            d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
          />
        </svg>

        <ul className="animate-ball">
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
          <li className="ball"></li>
        </ul>
      </section>

      <section className="workshop-text">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h3>
              <p>
                Duis a felis mi. Mauris ac ante eget neque imperdiet molestie et id lacus. Nullam
                sodales, tellus non efficitur dignissim, metus lacus posuere quam, nec facilisis
                massa erat non elit. Etiam posuere tortor at lorem porttitor, sit amet sollicitudin
                ex dignissim. Duis eros risus, gravida sed erat nec, dapibus elementum velit. Sed et
                velit a velit feugiat viverra bibendum vitae elit. Integer nec nisi orci. Aenean
                varius commodo ligula eu condimentum. Proin lacinia, mi non convallis eleifend, sem
                augue mattis sem, nec interdum augue mi vitae orci. Sed ac quam lacus. Pellentesque
                posuere, turpis ultrices porttitor porta, tortor arcu luctus leo, et viverra nunc
                turpis non elit. Vivamus erat dui, suscipit eget velit a, faucibus sollicitudin
                metus. Curabitur id ligula non odio facilisis lacinia.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="workshop">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Calendar onChange={onChange} 
//                onClick={clickHandler}
                showWeekNumbers
                value={date}/>
            </div>
            <div className="col-md-6">
              <form className="workshop-contact-form" onSubmit={handleSubmit}>
                {formState === 'initial' && (
                  <>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name*"
                      required
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />

                    <input
                      type="email"
                      name="email"
                      placeholder="Email*"
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />

                    <input
                      type="telephone"
                      name="telephone"
                      placeholder="Telephone*"
                      required
                      value={telephone}
                      onChange={e => setTelephone(e.target.value)}
                    />

                    <input
                      type="text"
                      name="organization"
                      placeholder="Organization"
                      value={organization}
                      onChange={e => setOrganization(e.target.value)}
                    />

                    <input
                      name="remark"
                      placeholder="Remark"
                      value={remark}
                      onChange={e => setRemark(e.target.value)}
                    ></input>

                    <button type="submit" className="pix-btn submit-btn">
                      <span className="btn-text">Register!</span>
                      <i className="fas fa-spinner fa-spin"></i>
                    </button>
                    <input type="hidden" name="recaptcha_response" id="recaptchaResponse" />
                  </>
                )}
                {formState === 'pending' && (
                  <div className="row">
                    <div className="content">Sending...</div>
                  </div>
                )}
                {formState === 'sent' && (
                  <div className="row">
                    <div className="content">Your registration was sent successfully.</div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Workshop;

// TEMPORARY BUILD FIX, THIS WAS THE ORIGINAL:
/* export const pageQuery = graphql`
 *   query {
 *     site {
 *       siteMetadata {
 *         title
 *       }
 *     }
 *     allConfig(filter: { name: { eq: "BACKEND_URL" } }) {
 *       nodes {
 *         name
 *         value
 *       }
 *     }
 *   }
 * `; */
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allConfig(filter: { name: { eq: "BACKEND_URL" } }) {
      nodes {
        name
        value
      }
    }
  }
`;
